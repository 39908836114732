import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Styling Your Teeth to Your Smile | Porcelain Veneers Blog" 
    description="Want a smile that suits you perfectly?  With our cosmetic dentistry technology, we can help you to design the smile which is best suited for your facial features." 
    keywords="cosmetic dentistry" />
    <main className="site-content prindex" role="main">
    <section id="main-top-img" className="blog hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <div className="blog-news-art">
              <h1>Styling your teeth to your smile</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <p>Have you even thought about the perfect smile for your face? What would you change? I am sure you are thinking of a few things that you have seen that you would love to have to work with, but what if I told you could mold together your favorite aspects of your ideal smile to create the perfect smile for your features. Well, here at The Smile Stylist we can do that and more!
            </p>
            <img src={ require("../../images/desktop/Styling your smile.png" )} alt="Blog Banner" className="bl__img" />
            <h3>The other guy's methods</h3>
            <p>When you go to another dentist for your cosmetic surgery, typically you are going to do through the dreaded catalogue. This means flipping through hundreds if not thousands of generic looks that might look good on you, but you will not know until after the surgery. This kind of surgery consultation is considered the standard in our industry, and to be perfectly honest, our team hates it! So, what can we offer you?
            </p>
            <h3>The Smile Stylist Difference</h3>
            <p>We are not like the other guys, nor do we settle for the standard, instead the team at The Smile Stylist will work with you to take components of smiles that you like, and apply it to your features to create the perfect smile. So how do we do that?
            </p>
            <p>We focus on the various facial features that help craft the smile and move from there. Here are just a few of the features that we can work on for you.
            </p>
            <img src={ require("../../images/desktop/Styling your smile 2.png" )} alt="Styling Your Teeth" className="bl__img" />
            <h3>Lip Size</h3>
            <p>Lip size is extremely important to our clients, and our ability to craft the perfect set of lips is second to none in the industry. We will work with you to ensure the lips at the end of the process not only fit your face but will allow you to make the perfect smile that you have always dreamed of.
            </p>
            <h3>Gum Structure</h3>
            <p>No matter if you are showing too much gum, or want to show a little more, the team at The Smile Stylist can craft he perfect gum line and structure to ensure you have the confidence to smile, and great oral hygiene. We can customise this feature as much as necessary, and with out a great team of cosmetic surgeons, gum structure is one of our specialities.
            </p>
            <h3>Teeth</h3>
            <p>One of the biggest complaints from many of our clients is the look of their teeth. It could be they are not straight or that they were blessed with large gaps, the reason does not matter, we are all about results. With our teeth work you will be able to have a smile that you would love to show off, and no matter the issues you are facing, our team of expert surgeons will be able to make your teeth Hollywood Red Carpet ready!
            </p>
            <p>Styling your teeth to your smile is a challenge, and it is part of the reason the many cosmetic dentists do not offer this kind of service. However, here at The Smile Stylist, we love the challenge and no matter if you are looking for lip size, gum structure, teeth or other work, our team will be able to find and sculp the features to ensure you have your perfect smile!
            </p>
          </div>
          <div className="col-sm-4 blog-right">
            <a href="/virtual-smile-makeover"><img alt="Virtual Smile Consultation" src={ require("../../images/desktop/get-virtual-smile.png" )} className="img-responsive wow animated zoomIn" data-wow-delay="0.3s" /></a>
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage